import {useCallback, useEffect, useMemo, useState} from "react";
import "./app.css";

function App() {
    const {kakao} = window;
    const geocoder = new kakao.maps.services.Geocoder();
    const [location, setLocation] = useState({
        lat: 0,
        lon: 0
        // lat: 37.550615003770446,
        // lon: 127.05177076264822
    });
    const [addrName, setAddrName] = useState("");
    const { lat, lon } = location;

    useMemo(() => { // 현재 위치 받아오기
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                setLocation({
                    lat: position.coords.latitude,
                    lon: position.coords.longitude,
                });
            }, () => {
                setLocation({
                    lat: 37.550615003770446,
                    lon: 127.05177076264822
                });
                alert("현재 위치를 받아올 수 없습니다.");
            });
        }
    }, [navigator.geolocation.getCurrentPosition]);

    useEffect(() => {
        const container = document.getElementById("map");
        const options = {
            center: new kakao.maps.LatLng(lat, lon),
            level: 3
        };

        const map = new kakao.maps.Map(container, options);
        const locPosition = new kakao.maps.LatLng(lat, lon);

        new kakao.maps.Marker({
            map: map,
            position: locPosition
        });
    }, [lat, lon]);

    useEffect(() => {
        geocoder.coord2Address(lon, lat, (result, status) => {
            if (status === kakao.maps.services.Status.OK) {
                setAddrName(result[0].road_address.address_name);
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(result[0].road_address.address_name);
                }
            }
        });
    }, [lon, lat, window.ReactNativeWebView]);

    // // React Native 에서 데이터 받아오기
    // const getNativeData = useCallback(({ data }) => {
    //     if (typeof data === "string") {
    //         // alert(data);
    //         // setLocation(JSON.parse(data));
    //     }
    // }, []);
    //
    //  useEffect(() => {
    //     if (window.ReactNativeWebView) {
    //         // window.ReactNativeWebView.postMessage("GET_COORDS");
    //     }
    //      window.addEventListener("message", getNativeData);
    //      return () => window.removeEventListener("message", getNativeData);
    // }, []);

    useEffect(() => {
        console.info(addrName);
        console.info(location);
        // alert(addrName);
    }, [addrName, location]);

    return (
        <div>
            <div id="map" style={{width: "100vw", height: "100vh"}} />
        </div>
    );
}

export default App;
